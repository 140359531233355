<template>
  <div id="app">
    <v-app>
          <router-view/>
    </v-app>
  </div>
</template>

<script>

import Snowfall from "@/components/Snowfall.vue"; // Asegúrate de ajustar la ruta de importación

export default {
  name: 'App',
  components: {
    Snowfall,
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #4e27eb;
  caret-color: #4e27eb;
}

.swal2-popup.swal2-toast .swal2-title {
    font-family: 'dosisBold' !important;
    font-size: 18px !important;
}

.container--fluid{
  padding: 0px !important;
}

.row{
  margin: 0px !important;
}

.theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before,
.theme--light.v-btn:focus:before {
  opacity: 1 !important;
  background-color: #ffffff00;
  border-bottom: 4px solid #f06291;
  
}

.v-btn--active .l-btn {
  color: #4e27eb;
}

.l-btn {
  margin-bottom: 0rem;
  color: #4e27eb;
}

.l-btn:active {
  margin-bottom: 0rem;
  color: #4e27eb !important;
}

@font-face {
  font-family: "robotoLight";
  src: url("/src/assets/fonts/roboto/Roboto-Light.ttf");
}

</style>
